import * as TYPES from './mutations-types'

export default {
  [TYPES.SET_USERS_LIST] (state, loadedList) {
    state.usersList = loadedList
  },
  [TYPES.SET_MANAGE_USER] (state, user) {
    state.manageUser = user
  },
  [TYPES.UPDATE_USER] (state, userPayload) {
    state.manageUser = userPayload

    state.usersList.forEach((user, index) => {
      if (user.id === userPayload.id) state.usersList[index] = userPayload
    })
  },
  [TYPES.SET_USER_SESSIONS_LIST] (state, loadedList) {
    state.userSessionsList = loadedList
  },
  [TYPES.SET_USER_LOGINS_LIST] (state, loadedList) {
    state.userLoginsList = loadedList
  },
  [TYPES.SET_USER_EMAIL_LOGS_LIST] (state, loadedList) {
    state.userEmailLogsList = loadedList
  },
  [TYPES.SET_USER_LEARNING_PATH_LIST] (state, loadedList) {
    state.userLearningPathList = loadedList
  },
  [TYPES.SET_USER_CANCELED_LIST] (state, loadedList) {
    state.userCanceledList = loadedList
  }

}
