import config from '@/config'

const { ANALYTICS_TOKEN } = config

export const analyticsMixin = {
  methods: {
    analyticsEnabled () {
      return Boolean(ANALYTICS_TOKEN)
    },
    analyticsEvent (action, properties) {
      if (this.analyticsEnabled()) {
        this.$ma.trackEvent({ action: action, properties: properties }, [])
      }
    },
    analyticsReset () {
      if (this.analyticsEnabled()) {
        this.$ma.reset()
      }
    },
    analyticsIdentify (userId) {
      if (this.analyticsEnabled()) {
        this.$ma.identify({ userId: userId, options: { newUser: true } })
        this.$ma.setUsername(userId)
      }
    },
    analyticsSetSuperProperties (properties) {
      if (this.analyticsEnabled()) {
        this.$ma.setSuperPropertiesOnce(properties)
      }
    }
  }
}
