import * as TYPES from './mutations-types'

export default {
  [TYPES.SET_LEVELS_LIST] (state, loadedList) {
    state.levelsList = loadedList
  },
  [TYPES.SET_ACHIEVEMENTS_LIST] (state, loadedList) {
    state.achievementsList = loadedList
  },
  [TYPES.SET_PLAYERS_LIST] (state, loadedList) {
    state.playersList = loadedList
  },
  [TYPES.SET_PLAYER_PROFILE] (state, profile) {
    state.playerProfile = profile
  }
}
