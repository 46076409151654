import * as requests from '@/api/Email'

function attemptGetSystemEmailList (pagination) {
  return requests.getSystemEmailList(pagination)
    .then(systemEmailsList => {
      return {
        data: systemEmailsList.data.data,
        firstPage: systemEmailsList.data.firstPage,
        lastPage: systemEmailsList.data.lastPage,
        nextPage: systemEmailsList.data.nextPage,
        previousPage: systemEmailsList.data.previousPage,
        total: systemEmailsList.data.total
      }
    })
}

function attemptUpdateSystemEmail (data) {
  return requests.updateSystemEmail(data)
    .then(response => {
      return response
    })
}

function attemptManageSystemEmail (data) {
  return requests.getSystemEmail(data)
    .then(response => {
      return response.data
    })
}

function attemptViewSystemEmail (systemEmailId) {
  return requests.viewSystemEmail(systemEmailId)
    .then(response => {
      return response
    })
}

function attemptToggleSystemEmail (systemEmailId) {
  return requests.toggleSystemEmail(systemEmailId)
    .then(response => {
      return response.data
    })
}

export const services = {
  attemptGetSystemEmailList,
  attemptUpdateSystemEmail,
  attemptManageSystemEmail,
  attemptViewSystemEmail,
  attemptToggleSystemEmail
}
