import * as services from '../services'

export const attemptGetReportListStudents = (_, payload) => {
  return services.getReportListStudents(payload)
}

export const attemptGetReportListEnrollments = (_, payload) => {
  return services.getReportListEnrollments(payload)
}

export const attemptGetReportListSurveys = (_, payload) => {
  return services.getReportListSurveys(payload)
}

export const attemptGetReportListSurveyAnswers = (_, payload) => {
  return services.getReportListSurveyAnswers(payload)
}

export const attemptGetReportListComments = (_, payload) => {
  return services.getReportListComments(payload)
}

export const attemptGetReportListSubscriptionSurveyAnswers = (_, payload) => {
  return services.getReportListSubscriptionSurveyAnswers(payload)
}

export const attemptGetReportCanvasDiscussion = (_, payload) => {
  return services.getReportCanvasDiscussion(payload)
}

export const attemptGetSolutionSurveyList = (_, idSolution) => {
  return services.getSolutionSurveyList(idSolution)
}

export const attemptGetSurveyList = (_, surveyType = null) => {
  return services.getSurveyList(surveyType)
}

export const attemptGetReportListSolutions = (_, payload) => {
  return services.getReportListSolutions(payload)
}

export const attemptGetReportListLearningPaths = (_, payload) => {
  return services.getReportListLearningPaths(payload)
}

export const attemptGetReportListPrograms = (_, payload) => {
  return services.getReportListPrograms(payload)
}

export const attemptGetReportListRaeServices = (_, payload) => {
  return services.getReportListRaeServices(payload)
}

export const attemptGetReportListSolutionAlerts = (_, payload) => {
  return services.getReportListSolutionAlerts(payload)
}

export const attemptGetReportListSasEvents = (_, payload) => {
  return services.getReportListSasEvents(payload)
}

export const attemptReportDownload = (_, payload) => {
  return services.reportDownload(payload)
}

export const attemptCheckReport = (_, payload) => {
  return services.checkReport(payload)
}

export const attemptGetReportListEnrollmentLearningPaths = (_, payload) => {
  return services.getReportEnrollmentLearningPaths(payload)
}

export const attemptGetReportListEnrollmentPrograms = (_, payload) => {
  return services.getReportEnrollmentPrograms(payload)
}

export const attemptGetReportListFinancial = (_, payload) => {
  return services.getReportFinancial(payload)
}

export const attemptGetReportEventsList = (_, payload) => {
  return services.getReportEvents(payload)
}
