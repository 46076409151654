import { sync } from 'vuex-router-sync'
import Vue from 'vue'
import App from './Main.vue'
import router from './router'
import i18n from '@/support/i18n'
import store from './store'
import httpPlugin from '@/support/http'
import Vuelidate from 'vuelidate'
import vClickOutside from 'v-click-outside'
import vueCookie from 'vue-cookie'
import VueClipboard from 'vue-clipboard2'
import VueShave from 'vue-shave'
import VDragged from 'v-dragged'
import VueMq from 'vue-mq'
import VueObserveVisibility from 'vue-observe-visibility'
import config from '@/config'
import { mixin } from './mixins/index'
import { analyticsMixin } from './mixins/analyticsMixin'
import VueTouch from 'vue-touch'
import VueEditor from 'vue2-editor'
import * as dynamicHeaderHelper from '@/support/utils/dynamicHeaderHelper'
import VTooltip from 'v-tooltip'
import anime from 'vue-animejs'
import AxiosPlugin from 'vue-axios-cors'

const {
  ENVIRONMENT,
  THEME_NAME,
  APP_TITLE,
  APP_DESCRIPTION
} = config

const theme = THEME_NAME

if (theme !== 'default') {
  require(`./assets/styles/themes/${theme}/global.css`)
}

Vue.config.productionTip = false

Vue.config.devtools = ENVIRONMENT === 'dev'
Vue.config.performance = true
Vue.use(VueTouch)
Vue.use(anime)

Vue.use(httpPlugin, {
  store,
  router
})

Vue.use(Vuelidate)
Vue.use(vClickOutside)
Vue.use(vueCookie)
Vue.use(VueShave)
Vue.use(VDragged)
Vue.use(VueObserveVisibility)
Vue.use(AxiosPlugin)

Vue.use(VueMq, {
  breakpoints: {
    mobile: 1024,
    tablet: 1025,
    desktop: Infinity
  }
})

Vue.use(VueEditor)
Vue.use(VTooltip)

Vue.use(VueClipboard)

Vue.mixin(mixin)
Vue.mixin(analyticsMixin)

dynamicHeaderHelper.setAll({
  THEME_NAME,
  APP_DESCRIPTION,
  APP_TITLE
})

store.state.fullscreen = false

sync(store, router)

export const app = new Vue({
  el: '#app',
  name: 'App',
  store,
  router,
  i18n,
  template: '<App/>',
  render: h => h(App)
})
