export default {
  feedback: {
    title: null,
    message: null,
    textColor: null,
    backgroundColor: null
  },
  fetching: false,
  language: null,
  accessibility: false,
  accessibilityFontSize: 0,
  fullscreen: false
}
