import store from '@/store'
import * as services from '../services'
import * as TYPES from './mutations-types'
import { setToken as httpSetToken } from '@/support/http'
import { app } from '@/main'

export const attemptCheckUserToken = ({ getters }) => {
  const state = {
    token: null,
    refreshToken: null
  }

  if (getters.getUserToken && getters.getUserRefreshToken) {
    state.token = getters.getUserToken
    state.refreshToken = getters.getUserRefreshToken
  }

  return Promise.resolve(state)
}

export const updateToken = ({ commit }, data) => {
  httpSetToken(data)
  commit(TYPES.SET_USER_TOKEN, data.token || null)
  commit(TYPES.SET_USER_REFRESH_TOKEN, data.refreshToken || null)
}

export const attemptSignIn = ({ commit }, payload) => {
  return services.signIn(payload)
    .then(data => {
      httpSetToken(data)
      commit(TYPES.SET_USER_TOKEN, data.token || null)
      commit(TYPES.SET_USER_REFRESH_TOKEN, data.refresh_token || null)
    })
}

export const attemptLogout = ({ commit }) => {
  commit(TYPES.SET_USER_TOKEN, null)
  store.dispatch('resetPagination')
  if (app.$router.currentRoute.name === 'signin') return
  app.$router.push({ name: 'signin' })
}

export const attemptGetLoggedUser = ({ commit }) => {
  return services.getLoggedUser()
    .then(({ data }) => {
      const userData = {}

      userData.permissions = data.user.userProfile[0].profile.access.map((access) => {
        return {
          context: access.contextName,
          access: access.contextAccess
        }
      })

      userData.roles = data.user.roles
      commit(TYPES.SET_USER_ROOT, data.user.roles.includes('ROLE_ROOT'))
      commit(TYPES.SET_USER_LOGGED_DATA, userData)

      return data
    })
}
