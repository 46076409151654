import { http } from '@/support/http'
import { createQuery } from '@/support/utils/paginatorQueryBuilder'

export const getFinanceList = (pagination) => {
  const queryString = createQuery(pagination)

  // return financeList
  return http.get(`api/financial/orders?${queryString}`)
}

export const getFinance = (id) => {
  const url = `api/financial/orders/${id}`

  return http.get(url)
}

export const syncOrder = (orderId) => {
  return http.post(`api/financial/sync/order/${orderId}`)
}

export const syncAllOrders = () => {
  return http.post('api/financial/sync/orders')
}

export const generateReceipt = () => {
  const url = `api/generateReceipt/`

  return http.get(url)
}
