import * as TYPES from './mutations-types'

export default {
  [TYPES.SET_BANNERS_LIST] (state, loadedList) {
    state.bannersList = loadedList
  },
  [TYPES.SET_MANAGE_BANNER] (state, banner) {
    state.manageBanner = banner
  },
  [TYPES.SAVE_BANNER] (state, banner) {
    state.manageBanner = banner
  }
}
