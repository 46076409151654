import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptGetSystemEmailsList = ({ commit }, pagination) => {
  return services.getSystemEmailsList(pagination)
    .then(systemEmailsList => {
      commit(TYPES.SET_SYSTEM_EMAILS_LIST, systemEmailsList.data.data)

      return {
        data: systemEmailsList.data.data,
        firstPage: systemEmailsList.data.firstPage,
        lastPage: systemEmailsList.data.lastPage,
        nextPage: systemEmailsList.data.nextPage,
        previousPage: systemEmailsList.data.previousPage,
        total: systemEmailsList.data.total
      }
    })
}

export const attemptUpdateSystemEmail = (_, data) => {
  return services.updateSystemEmail(data)
    .then(response => {
      return response
    })
}

export const attemptManageSystemEmail = (_, data) => {
  return services.getSystemEmail(data)
    .then(response => {
      return response.data
    })
}

export const attemptViewSystemEmail = (_, systemEmailId) => {
  return services.viewSystemEmail(systemEmailId)
    .then(response => {
      return response
    })
}

export const attemptToggleSystemEmails = (_, systemEmailId) => {
  return services.toggleSystemEmail(systemEmailId)
    .then(response => {
      return response.data
    })
}

export const attemptGetFiltersList = (_, pagination) => {
  return services.getFiltersList(pagination)
    .then(filtersList => {
      return {
        data: filtersList.data.data,
        firstPage: filtersList.data.firstPage,
        lastPage: filtersList.data.lastPage,
        nextPage: filtersList.data.nextPage,
        previousPage: filtersList.data.previousPage,
        actualPage: filtersList.data.actualPage,
        total: filtersList.data.total
      }
    })
}

export const attemptGetFilter = (_, id) => {
  return services.getFilter(id)
    .then(response => {
      return response.data
    })
}

export const attemptSaveFilter = (_, data) => {
  return services.saveFilter(data)
    .then(response => {
      return response
    })
}

export const attemptUpdateFilter = (_, data) => {
  return services.updateFilter(data)
    .then(response => {
      return response
    })
}

export const attemptRunFilter = (_, data) => {
  return services.runFilter(data)
    .then(response => {
      return response.data
    })
}

export const attemptToggleActiveFilter = (_, id) => {
  return services.toggleActiveFilter(id)
    .then(response => {
      return response.data
    })
}

export const attemptGetMediationPlanList = (_, pagination) => {
  return services.getMediationPlanList(pagination)
    .then(mediationPlansList => {
      return {
        data: mediationPlansList.data.data,
        firstPage: mediationPlansList.data.firstPage,
        lastPage: mediationPlansList.data.lastPage,
        nextPage: mediationPlansList.data.nextPage,
        previousPage: mediationPlansList.data.previousPage,
        actualPage: mediationPlansList.data.actualPage,
        total: mediationPlansList.data.total
      }
    })
}

export const attemptGetMediationPlan = (_, id) => {
  return services.getMediationPlan(id)
    .then(response => {
      return response.data
    })
}

export const attemptCreateMediationPlan = (_, form) => {
  return services.createMediationPlan(form)
    .then(response => {
      return response
    })
}

export const attemptUpdateMediationPlan = (_, id) => {
  return services.updateMediationPlan(id)
    .then(response => {
      return response
    })
}

export const attemptToggleActiveMediationPlan = (_, id) => {
  return services.toggleActiveMediationPlan(id)
    .then(response => {
      return response.data
    })
}

export const attemptRemoveMediationPlan = (_, id) => {
  return services.removeMediationPlan(id)
    .then(response => {
      return response.data
    })
}

export const attemptGetLogList = (_, payload) => {
  return services.getLogList(payload)
    .then(response => {
      return response.data
    })
}
