import { http } from '@/support/http'
import { createQuery } from '@/support/utils/paginatorQueryBuilder'
import qs from 'qs'

export const getReportListStudents = (params) => {
  let paramsQuery = ''

  if (params) {
    paramsQuery = '?' + qs.stringify(params)
  }

  return http.get('api/report/list-students' + paramsQuery)
}

export const getReportListEnrollments = (params) => {
  return http.post('api/report/list-enrollments', params)
}

export const getReportListSurveys = (params) => {
  return http.post('api/report/list-surveys', params)
}

export const getReportListSurveyAnswers = (params) => {
  return http.post('api/report/list-survey-answers', params)
}

export const getReportListSubscriptionSurveyAnswers = (params) => {
  return http.post('api/report/list-subscription-survey-answers', params)
}

export const getReportListComments = (params) => {
  return http.post('api/report/list-assessments/resume', params)
}

export const getReportCanvasDiscussion = (idSolution) => {
  const params = {
    solutions: idSolution
  }

  return http.post('api/report/canvas-discussions', params)
}

export const getSolutionSurveyList = (idSolution) => {
  const queryString = createQuery({
    filter: {
      solution_id: idSolution
    },
    limit: 9999
  })

  return http.get(`api/survey?${queryString}`)
}

export const getSurveyList = (surveyType) => {
  const queryString = createQuery(surveyType)

  return http.get(`api/survey?${queryString}`)
}

export const getReportListSolutions = (params) => {
  let paramsQuery = ''

  if (params) {
    paramsQuery = '?' + qs.stringify(params)
  }

  return http.get('api/report/list-solutions/resume' + paramsQuery)
}

export const getReportListLearningPaths = (params) => {
  let paramsQuery = ''

  if (params) {
    paramsQuery = '?' + qs.stringify(params)
  }

  return http.get('api/report/list/learning-paths/resume' + paramsQuery)
}

export const getReportListPrograms = (params) => {
  let paramsQuery = ''

  if (params) {
    paramsQuery = '?' + qs.stringify(params)
  }

  return http.get('api/report/list/programs/resume' + paramsQuery)
}

export const getReportListRaeServices = (params) => {
  let paramsQuery = ''

  if (params) {
    paramsQuery = '?' + qs.stringify(params)
  }

  return http.get('api/report/list-rae-services' + paramsQuery)
}

export const getReportListSolutionAlerts = (params) => {
  let paramsQuery = ''

  if (params) {
    paramsQuery = '?' + qs.stringify(params)
  }

  return http.get('api/report/list-solution-alerts' + paramsQuery)
}

export const getReportListSasEvents = (params) => {
  let paramsQuery = ''

  if (params) {
    paramsQuery = '?' + qs.stringify(params)
  }

  return http.get('api/report/list-sas-events' + paramsQuery)
}

export const reportDownload = (reportId) => {
  return http.get(`api/report/${reportId}/download`, { responseType: 'blob' })
}

export const checkReport = (reportId) => {
  return http.get(`api/report/${reportId}/status`)
}

export const getReportEnrollmentLearningPaths = (params) => {
  let paramsQuery = ''

  if (params) {
    paramsQuery = '?' + qs.stringify(params)
  }

  return http.get('api/report/list-enrollment-learning-paths' + paramsQuery)
}

export const getReportEnrollmentPrograms = (params) => {
  let paramsQuery = ''

  if (params) {
    paramsQuery = '?' + qs.stringify(params)
  }

  return http.get('api/report/list-enrollment-programs' + paramsQuery)
}

export const getReportFinancial = (params) => {
  let paramsQuery = ''

  if (params) {
    paramsQuery = '?' + qs.stringify(params)
  }

  return http.get('api/report/list-financial' + paramsQuery)
}

export const getReportEvents = (params) => {
  let paramsQuery = ''

  if (params) {
    paramsQuery = '?' + qs.stringify(params)
  }

  return http.get('api/report/list-events' + paramsQuery)
}
