import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptGetEventsList = ({ commit }, pagination) => {
  return services.getEventsList(pagination)
    .then(eventsList => {
      commit(TYPES.SET_EVENTS_LIST, eventsList.data.data)

      return {
        data: eventsList.data.data,
        actualPage: eventsList.data.actualPage,
        firstPage: eventsList.data.firstPage,
        lastPage: eventsList.data.lastPage,
        nextPage: eventsList.data.nextPage,
        previousPage: eventsList.data.previousPage,
        total: eventsList.data.total
      }
    })
}

export const attemptGetAgendasList = ({ commit }, pagination) => {
  return services.getAgendasList(pagination)
    .then(agendasList => {
      commit(TYPES.SET_AGENDAS_LIST, agendasList.data.data)

      return {
        data: agendasList.data.data,
        actualPage: agendasList.data.actualPage,
        firstPage: agendasList.data.firstPage,
        lastPage: agendasList.data.lastPage,
        nextPage: agendasList.data.nextPage,
        previousPage: agendasList.data.previousPage,
        total: agendasList.data.total
      }
    })
}

export const attemptGetEventsSearch = ({ commit }, pagination) => {
  return services.getEventsSearch(pagination)
    .then(eventsList => {
      commit(TYPES.SET_EVENTS_LIST, eventsList.data.data)

      return {
        data: eventsList.data.data,
        firstPage: eventsList.data.firstPage,
        lastPage: eventsList.data.lastPage,
        nextPage: eventsList.data.nextPage,
        previousPage: eventsList.data.previousPage,
        total: eventsList.data.total
      }
    })
}

export const attemptSaveEvent = (_, data) => {
  return services.saveEvent(data)
    .then(response => {
      return response
    })
}

export const attemptUpdateActiveEvent = ({ commit }, event) => {
  return services.updateEvent(event)
    .then(response => {
      commit(TYPES.UPDATE_ACTIVE_EVENT, response.data)

      return response
    })
}

export const attemptSaveActiveEvent = ({ commit }, event) => {
  return services.saveEvent(event)
    .then(response => {
      commit(TYPES.UPDATE_ACTIVE_EVENT, response.data)

      return response
    })
}

export const attemptSaveActiveAgenda = ({ commit }, agenda) => {
  return services.saveAgenda(agenda)
    .then(response => {
      commit(TYPES.UPDATE_ACTIVE_AGENDA, response.data)

      return response
    })
}

export const attemptUpdateEvent = (_, data) => {
  return services.updateEvent(data)
    .then(response => {
      return response
    })
}

export const attemptUpdateAgenda = (_, data) => {
  return services.updateAgenda(data)
    .then(response => {
      return response
    })
}

export const attemptManageEvent = ({ commit }, eventId) => {
  if (eventId == null) {
    commit(TYPES.SET_MANAGE_EVENT, null)

    return
  }

  return services.getEvent(eventId)
    .then(response => {
      commit(TYPES.SET_MANAGE_EVENT, response.data || null)

      return response.data
    })
}

export const attemptManageAgenda = ({ commit }, agendaId) => {
  if (agendaId == null) {
    commit(TYPES.SET_MANAGE_AGENDA, null)

    return
  }

  return services.getAgenda(agendaId)
    .then(response => {
      commit(TYPES.SET_MANAGE_AGENDA, response.data || null)

      return response.data
    })
}

export const attemptRemoveEvent = (_, data) => {
  return services.removeEvent(data)
    .then(response => {
      return response
    })
}

export const attemptToggleActiveEvent = (_, idEvent) => {
  return services.toggleActiveEvent(idEvent)
}

export const attemptToggleActiveAgenda = (_, idAgenda) => {
  return services.toggleActiveAgenda(idAgenda)
}

export const setEventPagination = ({ commit }, filteredParams) => {
  commit(TYPES.SET_EVENT_PAGINATION, filteredParams)
}

export const resetEventPagination = ({ commit }) => {
  commit(TYPES.RESET_EVENT_PAGINATION)
}

export const setAgendaPagination = ({ commit }, filteredParams) => {
  commit(TYPES.SET_AGENDA_PAGINATION, filteredParams)
}

export const resetAgendaPagination = ({ commit }) => {
  commit(TYPES.RESET_AGENDA_PAGINATION)
}
