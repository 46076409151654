const ProgramsList = () => import('./components/ProgramsList')
const ProgramsManage = () => import('./components/ProgramsManage')
const ProgramsManageQualify = () => import('./components/ProgramsManageQualify')
const ProgramsManageSEO = () => import('./components/ProgramsManageSEO')
const ProgramsManageLink = () => import('./components/ProgramsManageLink')

export default [
  {
    name: 'programs.index',
    path: '/programs',
    component: ProgramsList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      module: 'program',
      permission: {
        context: 'programs',
        access: 'write'
      }
    }
  },
  {
    name: 'programs.manage',
    path: '/programs/manage/:id?',
    component: ProgramsManage,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      module: 'program',
      permission: {
        context: 'programs',
        access: 'write'
      }
    }
  },
  {
    name: 'programs.manage.qualify',
    path: '/programs/manage/:id/qualify/:isNew',
    component: ProgramsManageQualify,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      module: 'program',
      permission: {
        context: 'programs',
        access: 'write'
      }
    }
  },
  {
    name: 'programs.manage.seo',
    path: '/programs/manage/:id/seo/:isNew',
    component: ProgramsManageSEO,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      module: 'program',
      permission: {
        context: 'programs',
        access: 'write'
      }
    }
  },
  {
    name: 'programs.manage.link',
    path: '/programs/manage/:id/link/:isNew',
    component: ProgramsManageLink,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      module: 'program',
      permission: {
        context: 'programs',
        access: 'write'
      }
    }
  }
]
