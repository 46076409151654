import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptGetCommentsList = ({ commit }, pagination) => {
  return services.getCommentsList(pagination)
    .then(commentsList => {
      commit(TYPES.SET_COMMENTS_LIST, commentsList.data.data)

      return {
        data: commentsList.data,
        actualPage: commentsList.actualPage,
        firstPage: commentsList.firstPage,
        lastPage: commentsList.lastPage,
        nextPage: commentsList.nextPage,
        previousPage: commentsList.previousPage,
        total: commentsList.total
      }
    })
}

export const attemptApproveComment = (_, idComment) => {
  return services.approvedComment(idComment)
}

export const attemptDisapproveComment = (_, idComment) => {
  return services.disapprovedComment(idComment)
}
