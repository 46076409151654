import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptGetLevelsList = ({ commit }) => {
  return services.getLevelsList()
    .then(levelsList => {
      commit(TYPES.SET_LEVELS_LIST, levelsList.data)

      return {
        data: levelsList.data
      }
    })
}

export const attemptGetAchievementsList = ({ commit }) => {
  return services.getAchievementsList()
    .then(achievementsList => {
      commit(TYPES.SET_ACHIEVEMENTS_LIST, achievementsList.data)

      return {
        data: achievementsList.data
      }
    })
}

export const attemptGetRankingPlayersList = ({ commit }) => {
  return services.getRankingPlayersList()
    .then(playersList => {
      commit(TYPES.SET_PLAYERS_LIST, playersList.data)

      return {
        data: playersList.data
      }
    })
}

export const attemptGetPlayerProfile = ({ commit }, username ) => {
  return services.getPlayerProfile(username)
    .then(response => {
      commit(TYPES.SET_PLAYER_PROFILE, response.data)

      return {
        data: response.data
      }
    })
}
