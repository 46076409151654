const state = {
  eventsList: [],
  agendasList: [],
  manageEvent: null,
  manageAgenda: null,
  eventPagination: {
    query: {
      name: null
    },
    filter: {},
    order: {
      active: 'desc',
      name: 'asc'
    },
    limit: 12,
    page: 1,
    lastPage: null
  },

  agendaPagination: {
    query: {
      name: null
    },
    filter: {},
    order: {
      active: 'desc',
      start_date: 'asc'
    },
    limit: 12,
    page: 1,
    lastPage: null
  }
}

export const eventPagination = state.eventPagination
export const agendaPagination = state.agendaPagination

export default state
