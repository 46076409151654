import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptGetBannersList = ({ commit }, pagination) => {
  return services.getBannersList(pagination)
    .then(bannersList => {
      commit(TYPES.SET_BANNERS_LIST, bannersList.data.data)

      return {
        data: bannersList.data.data,
        firstPage: bannersList.data.firstPage,
        lastPage: bannersList.data.lastPage,
        nextPage: bannersList.data.nextPage,
        previousPage: bannersList.data.previousPage,
        total: bannersList.data.total
      }
    })
}

export const attemptToggleBannerActive = (_, id) => {
  return services.toggleBannerActive(id)
    .then(response => {
      return response
    })
}

export const attemptRemoveBanner = (_, id) => {
  return services.removeBanner(id)
    .then(response => {
      return response
    })
}

export const attemptManageBanner = ({ commit }, id) => {
  if (id == null) {
    commit(TYPES.SET_MANAGE_BANNER, null)

    return
  }

  return services.getBanner(id)
    .then(response => {
      commit(TYPES.SET_MANAGE_BANNER, response.data || null)

      return response.data
    })
}

export const attemptSaveBanner = ({ commit }, banner) => {
  return services.saveBanner(banner)
    .then(response => {
      commit(TYPES.SAVE_BANNER, response.data)

      return response
    })
}

export const attemptUpdateBanner = ({ commit }, banner) => {
  return services.updateBanner(banner)
    .then(response => {
      commit(TYPES.SAVE_BANNER, response.data)

      return response
    })
}
