import * as requests from '@/api/Finance'

function attemptGetFinanceList (pagination) {
  return requests.getFinanceList(pagination)
    .then(financeList => {
      return {
        data: financeList.data.data,
        firstPage: financeList.data.firstPage,
        lastPage: financeList.data.lastPage,
        nextPage: financeList.data.nextPage,
        previousPage: financeList.data.previousPage,
        actualPage: financeList.data.actualPage,
        total: financeList.data.total
      }
    })
}

function attemptGetFinanceById (id) {
  return requests.getFinance(id)
    .then(response => {
      return response.data
    })
}

function attemptSyncOrder (orderId) {
  return requests.syncOrder(orderId)
}

function attemptSyncAllOrders () {
  return requests.syncAllOrders()
}

function attemptGenerateReceipt () {
  return requests.generateReceipt()
    .then(response => {
      return response.data
    })
}

export const services = {
  attemptGetFinanceList,
  attemptGetFinanceById,
  attemptSyncOrder,
  attemptSyncAllOrders,
  attemptGenerateReceipt
}
