import { http } from '@/support/http'
import qs from 'qs'

export const getTotalEnrollments = () => {
  return http.get('api/dashboard/enrollments/total')
}

export const getTotalEnrollmentsBySolution = () => {
  return http.get('api/dashboard/enrollments/solutions/total')
}

export const getTotalRegistersLastWeeks = () => {
  return http.get('api/dashboard/users/registers/total')
}

export const getTotalEnrollmentsByDay = (days) => {
  const paramsQuery = qs.stringify({
    days: days || 9
  })
  return http.get(`api/dashboard/enrollments/days?${paramsQuery}`)
}

export const getTotalEnrollmentsSolutionsByStatus = () => {
  return http.get('api/dashboard/enrollments/solutions/status')
}

export const getTotalEnrollmentsTracksByStatus = () => {
  return http.get('api/dashboard/enrollments/learning-paths/status')
}
