import { http } from '@/support/http'

const APP_KEY = process.env.VUE_APP_APP_KEY
const APP_SECRET = process.env.VUE_APP_APP_SECRET

export const signIn = payload => {
  return new Promise((resolve, reject) => {
    const data = {
      _username: payload.username,
      _password: payload.password,
      client_id: APP_KEY,
      client_secret: APP_SECRET
    }

    return http.post('api/login_check', data).then((ret) => {
      resolve(ret.data)
    }).catch(() => {
      // eslint-disable-next-line
      reject({ code: 'INVALID_CREDENTIALS', message: 'Invalid credentials' })
    })
  })
}

export const getLoggedUser = () => {
  return http.get('api/user/myself')
}
