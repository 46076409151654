import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ptbr from './pt-br'
import config from '@/config'
import { MAIN_SET_LANGUAGE } from '../../store/types'

Vue.use(VueI18n)

const { DEFAULT_LANGUAGE } = config
const fallbackLocale = 'pt-br'
const loadedLanguages = ['pt-br']
const messages = {
  'pt-br': ptbr
}

function setI18nLanguage (i18n, lang) {
  i18n.locale = lang
  document.documentElement.lang = lang
}

export function loadLanguageAsync (i18n, lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(/* webpackChunkName: "lang-[request]" */ './' + (lang || fallbackLocale)).then(msgs => {
        i18n.setLocaleMessage(lang, msgs.default)
        loadedLanguages.push(lang)
        setI18nLanguage(i18n, lang)
      })
    }
    return Promise.resolve(setI18nLanguage(i18n, lang))
  }
  return Promise.resolve(lang)
}

function buildVuexI18nPlugin (i18n) {
  return store => {
    store.subscribe(mutation => {
      if (mutation.type === MAIN_SET_LANGUAGE) {
        loadLanguageAsync(i18n, mutation.payload)
      }
    })
  }
}

const i18n = new VueI18n({
  locale: fallbackLocale,
  fallbackLocale: fallbackLocale,
  silentTranslationWarn: !process.env.DEBUG,
  messages
})

if (DEFAULT_LANGUAGE !== fallbackLocale) {
  loadLanguageAsync(i18n, DEFAULT_LANGUAGE)
}

export const vuexI18nPlugin = buildVuexI18nPlugin(i18n)

export default i18n
