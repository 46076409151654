export const eventsList = getters => {
  return getters.eventsList
}

export const agendasList = getters => {
  return getters.agendasList
}

export const getManagingEvent = getters => {
  return getters.manageEvent
}

export const getManagingAgenda = getters => {
  return getters.manageAgenda
}

export const getEventPagination = getters => {
  return getters.eventPagination
}

export const getAgendaPagination = getters => {
  return getters.agendaPagination
}
