import * as TYPES from './mutations-types'

export default {
  [TYPES.SET_SESSIONS_LIST] (state, loadedList) {
    state.sessionsList = loadedList
  },
  [TYPES.SET_MANAGE_SESSION] (state, session) {
    state.manageSession = session
  },

  [TYPES.UPDATE_ACTIVE_SESSION] (state, session) {
    state.manageSession = session
  }
}
