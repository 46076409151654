import * as TYPES from './mutations-types'

export default {
  [TYPES.SET_MANAGEMENT_CERTIFICATES_LIST] (state, data) {
    state.certificates = data
  },
  [TYPES.SET_MANAGEMENT_TARGET_AUDIENCES_LIST] (state, data) {
    state.targetAudiences = data
  },
  [TYPES.SET_MANAGEMENT_PROFILES_LIST] (state, data) {
    state.profiles = data
  },
  [TYPES.SET_SURVEY_LIST] (state, data) {
    state.surveyList = data || []
  }
}
