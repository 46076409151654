import { services } from './services'

const initData = {
  systemEmailList: {
    data: [],
    firstPage: 0,
    lastPage: 0,
    nextPage: 0,
    previousPage: 0,
    total: 0
  }
}

const initialState = () => ({
  ...initData
})

const state = initialState()

const getters = {
  getSystemEmailList: (state) => state.systemEmailList
}

const mutations = {
  SET_SYSTEM_EMAIL_LIST (state, payload) {
    state.systemEmailList = payload
  }
}

const actions = {
  async fetchSystemEmailList ({ commit }, pagination) {
    return await services.attemptGetSystemEmailList(pagination).then(
      ({ data, firstPage, lastPage, nextPage, previousPage, total }) => {
        commit('SET_SYSTEM_EMAIL_LIST', {
          data,
          firstPage,
          lastPage,
          nextPage,
          previousPage,
          total
        })

        return data
      })
  }
}

export const EmailModule = {
  module: {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }
}
