export const SET_EVENTS_LIST = 'events/SET_EVENTS_LIST'
export const SET_AGENDAS_LIST = 'events/SET_AGENDAS_LIST'
export const SET_MANAGE_EVENT = 'events/SET_MANAGE_EVENT'
export const SET_MANAGE_AGENDA = 'events/SET_MANAGE_AGENDA'
export const UPDATE_ACTIVE_EVENT = 'events/UPDATE_ACTIVE_EVENT'
export const UPDATE_ACTIVE_AGENDA = 'events/UPDATE_ACTIVE_AGENDA'
export const SET_EVENT_PAGINATION = 'events/SET_EVENT_PAGINATION'
export const RESET_EVENT_PAGINATION = 'events/RESET_EVENT_PAGINATION'
export const SET_AGENDA_PAGINATION = 'events/SET_AGENDA_PAGINATION'
export const RESET_AGENDA_PAGINATION = 'events/RESET_AGENDA_PAGINATION'
