import * as TYPES from './mutations-types'
import { mixin } from '../../../mixins'

export default {
  [TYPES.SET_PROGRAMS_LIST] (state, loadedList) {
    state.programsList = loadedList
  },
  [TYPES.SET_MANAGE_PROGRAM] (state, program) {
    state.manageProgram = mixin.methods.deepClone(program)
  },
  [TYPES.UPDATE_ACTIVE_PROGRAM] (state, program) {
    state.manageProgram = program
  }
}
