import { pagination as initialPagination } from './state'
import * as TYPES from './mutations-types'

export default {
  [TYPES.SET_EVENTS_LIST] (state, loadedList) {
    state.eventsList = loadedList
  },
  [TYPES.SET_AGENDAS_LIST] (state, loadedList) {
    state.agendasList = loadedList
  },
  [TYPES.SET_MANAGE_EVENT] (state, event) {
    state.manageEvent = event
  },
  [TYPES.UPDATE_ACTIVE_EVENT] (state, event) {
    state.manageEvent = event
  },
  [TYPES.SET_MANAGE_AGENDA] (state, agenda) {
    state.manageAgenda = agenda
  },
  [TYPES.UPDATE_ACTIVE_AGENDA] (state, agenda) {
    state.manageAgenda = agenda
  },
  [TYPES.SET_EVENT_PAGINATION] (state, pagination) {
    state.eventPagination = pagination
  },
  [TYPES.RESET_EVENT_PAGINATION] (state) {
    state.eventPagination = initialPagination
  },
  [TYPES.SET_AGENDA_PAGINATION] (state, pagination) {
    state.agendaPagination = pagination
  },
  [TYPES.RESET_AGENDA_PAGINATION] (state) {
    state.agendaPagination = initialPagination
  }
}
