import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptGetSessionsList = ({ commit }, pagination) => {
  return services.getSessionsList(pagination)
    .then(sessionsList => {
      commit(TYPES.SET_SESSIONS_LIST, sessionsList.data.data)

      return {
        data: sessionsList.data.data,
        firstPage: sessionsList.data.firstPage,
        lastPage: sessionsList.data.lastPage,
        nextPage: sessionsList.data.nextPage,
        previousPage: sessionsList.data.previousPage,
        total: sessionsList.data.total
      }
    })
}

// eslint-disable-next-line
export const attemptGetSessionStudentsList = (_, {session_id, pagination}) => {
  return services.getSessionStudentsList(session_id, pagination)
}

export const attemptSaveSessionAttendanceList = (_, payload) => {
  return services.saveSessionAttendanceList(payload.sessionId, payload.students)
}

export const attemptSaveSession = (_, data) => {
  return services.saveSession(data)
    .then(response => {
      return response
    })
}

export const attemptUpdateActiveSession = ({ commit }, session) => {
  return services.updateSession(session)
    .then(response => {
      commit(TYPES.UPDATE_ACTIVE_SESSION, response.data)

      return response
    })
}

export const attemptSaveActiveSession = ({ commit }, session) => {
  return services.saveSession(session)
    .then(response => {
      commit(TYPES.UPDATE_ACTIVE_SESSION, response.data)

      return response
    })
}

export const attemptUpdateSession = (_, data) => {
  return services.updateSession(data)
    .then(response => {
      return response
    })
}

export const attemptManageSession = ({ commit }, sessionId) => {
  if (sessionId == null) {
    commit(TYPES.SET_MANAGE_SESSION, null)

    return
  }

  return services.getSession(sessionId)
    .then(response => {
      commit(TYPES.SET_MANAGE_SESSION, response.data || null)

      return response.data
    })
}

export const attemptCancelSession = (_, data) => {
  return services.cancelSession(data)
    .then(response => {
      return response
    })
}

export const attemptListClosedSolutions = () => {
  return services.listClosedSolutions()
    .then(response => {
      return response
    })
}
