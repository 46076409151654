const Reports = () => import('./components/Reports')
const ReportStudentList = () => import('./components/ReportStudentList/')
const ReportEnrollmentList = () => import('./components/ReportEnrollmentList')
const ReportSurveys = () => import('./components/ReportSurveys')
const ReportSurveyAnswersList = () => import('./components/ReportSurveyAnswersList')
const ReportSubscriptionSurveyAnswersList = () => import('./components/ReportSubscriptionSurveyAnswersList')
const ReportCanvasDiscussion = () => import('./components/ReportCanvasDiscussion')
const ReportSolutionResumeList = () => import('./components/ReportSolutionResumeList')
const ReportLearningPathResumeList = () => import('./components/ReportLearningPathResumeList')
const ReportEnrollmentLearningPathResumeList = () => import('./components/ReportEnrollmentLearningPathResumeList')
const ReportProgramResumeList = () => import('./components/ReportProgramResumeList')
const ReportEnrollmentProgramResumeList = () => import('./components/ReportEnrollmentProgramResumeList')
const ReportRaeServiceList = () => import('./components/ReportRaeServiceList')
const ReportSolutionAlertList = () => import('./components/ReportSolutionAlertList')
const ReportSasEventList = () => import('./components/ReportSasEventList')
const ReportFinancialList = () => import('./components/ReportFinancialList')
const ReportCommentsList = () => import('./components/ReportCommentsList')
const ReportEventsList = () => import('./components/ReportEventsList')

export default [
  {
    name: 'reports.index',
    path: '/reports',
    component: Reports,
    meta: {
      hideNavbar: false,
      requiresAuth: true
    }
  },
  {
    name: 'reports.students',
    path: '/reports/list-students',
    component: ReportStudentList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports_students',
        access: 'write'
      }
    }
  },
  {
    name: 'reports.enrollments',
    path: '/reports/list-enrollments',
    component: ReportEnrollmentList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports_enrollments',
        access: 'write'
      }
    }
  },
  {
    name: 'reports.surveys',
    path: '/reports/surveys',
    component: ReportSurveys,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports_surveys_answers',
        access: 'write'
      }
    }
  },
  {
    name: 'reports.surveys.answers',
    path: '/reports/list-survey-answers',
    component: ReportSurveyAnswersList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports_surveys_answers',
        access: 'write'
      }
    }
  },
  {
    name: 'reports.subscription.surveys.answers',
    path: '/reports/list-subscription-survey-answers',
    component: ReportSubscriptionSurveyAnswersList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports_surveys_answers',
        access: 'write'
      }
    }
  },
  {
    name: 'reports.canvas.discussion',
    path: '/reports/canvas-discussion',
    component: ReportCanvasDiscussion,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports',
        access: 'write'
      }
    }
  },
  {
    name: 'reports.solutions.resume',
    path: '/reports/list-solutions-resume',
    component: ReportSolutionResumeList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports_solutions_resume',
        access: 'write'
      }
    }
  },
  {
    name: 'reports.learning.path.resume',
    path: '/reports/list-learning-path-resume',
    component: ReportLearningPathResumeList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports_learning_path_resume',
        access: 'write'
      }
    }
  },
  {
    name: 'reports.enrollment.learning.path.resume',
    path: '/reports/enrollment-learning-path-resume',
    component: ReportEnrollmentLearningPathResumeList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports_enrollments_learning_path',
        access: 'write'
      }
    }
  },
  {
    name: 'reports.program.resume',
    path: '/reports/list-program-resume',
    component: ReportProgramResumeList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports_programs_resume',
        access: 'write'
      }
    }
  },
  {
    name: 'reports.enrollment.program.resume',
    path: '/reports/enrollment-program-resume',
    component: ReportEnrollmentProgramResumeList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports_enrollments_program',
        access: 'write'
      }
    }
  },
  {
    name: 'reports.rae.services',
    path: '/reports/list-rae-services',
    component: ReportRaeServiceList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports_rae_services',
        access: 'write'
      }
    }
  },
  {
    name: 'reports.solution.alerts',
    path: '/reports/list-solution-alerts',
    component: ReportSolutionAlertList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports_solution_alerts',
        access: 'write'
      }
    }
  },
  {
    name: 'reports.sas.events',
    path: '/reports/list-sas-events',
    component: ReportSasEventList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports_sas_events',
        access: 'write'
      }
    }
  },
  {
    name: 'reports.financial',
    path: '/reports/financial',
    component: ReportFinancialList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports_financial',
        access: 'write'
      }
    }
  },
  {
    name: 'reports.comments',
    path: '/reports/comments',
    component: ReportCommentsList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports_comments',
        access: 'write'
      }
    }
  },
  {
    name: 'reports.events',
    path: '/reports/events',
    component: ReportEventsList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'reports_events',
        access: 'read'
      }
    }
  }
]
