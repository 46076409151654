import * as TYPES from './types'

export default {
  setFetching ({ commit }, obj) {
    commit(TYPES.MAIN_SET_FETCHING, obj)
  },

  setAccessibility ({ commit }) {
    commit(TYPES.MAIN_SET_ACCESSIBILITY)
  },

  setLanguage ({ commit }, obj) {
    commit(TYPES.MAIN_SET_LANGUAGE, obj)
  },

  setFontsize ({ commit }, obj) {
    commit(TYPES.MAIN_SET_FONTSIZE, obj)
  },

  setFullscreen ({ commit }) {
    commit(TYPES.MAIN_SET_FULLSCREEN)
  },

  setFeedback ({ commit }, obj) {
    commit(TYPES.MAIN_SET_FEEDBACK, obj)
  }
}
