import { http } from '@/support/http'
import { createQuery } from '../../../support/utils/paginatorQueryBuilder'
import { parseToFormDataWithArray } from '@/support/payloadParser'

export const getBannersList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/banners?${queryString}`)
}

export const toggleBannerActive = (id) => {
  return http.post(`api/banner/${id}/toggle`)
}

export const removeBanner = (id) => {
  return http.delete(`api/banner/${id}`)
}

export const getBanner = (id) => {
  return http.get(`api/banner/${id}`)
}

export const saveBanner = (data) => {
  const form = parseToFormDataWithArray(data)

  if (data.image && typeof data.image === 'object') {
    form.append('image', data.image, data.image.name)
  }

  if (data.imageMobile && typeof data.imageMobile === 'object') {
    form.append('imageMobile', data.imageMobile, data.imageMobile.name)
  }

  return http.post('api/banner', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const updateBanner = (data) => {
  const form = parseToFormDataWithArray(data)

  if (data.image && typeof data.image === 'object') {
    form.append('image', data.image, data.image.name)
  }

  if (data.imageMobile && typeof data.imageMobile === 'object') {
    form.append('imageMobile', data.imageMobile, data.imageMobile.name)
  }

  const id = data.id

  return http.post(`api/banner/${id}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
