import { services } from './services'

const initData = {
  // TODO Back: Substituir data fake por um array vazio []
  financeList: [
    {
      id: 10293,
      user: {
        name: 'Lucas Oliveira Haensch',
        cpf: '589.430.920-40'
      },

      paymentMethod: 'Cartão de Crédito',
      value: '2349,99',
      status: 'finished',
      createdAt: '2022-04-21T13:17:11-03:00',
      lastUpdate: '2022-04-22T13:17:11-03:00'
    },
    {
      id: 102,
      user: {
        name: 'João Caminhão dos Santos',
        cpf: '231.628.990-87'
      },

      paymentMethod: 'Boleto',
      value: '249,99',
      status: 'pending',
      createdAt: '2022-04-21T13:17:11-03:00',
      lastUpdate: '2022-04-22T13:17:11-03:00'
    },
    {
      id: 398423,
      user: {
        name: 'Maria Clara Nunes',
        cpf: '868.285.130-02'
      },

      paymentMethod: 'Paypal',
      value: '49,99',
      status: 'failed',
      createdAt: '2022-04-21T13:17:11-03:00',
      lastUpdate: '2022-04-22T13:17:11-03:00'
    }
  ]
}

const initialState = () => ({
  ...initData
})

const state = initialState()

const getters = {
  getFinanceList: (state) => state.financeList
}

const mutations = {
  SET_FINANCE_LIST (state, payload) {
    state.financeList = payload
  }
}

const actions = {
  async attemptGetFinanceList (_, pagination) {
    return await services.attemptGetFinanceList(pagination)
    // .then(response => {
    //   commit('SET_FINANCE_LIST', response.data)

    //   return response.data
    // })
  },

  async attemptGetFinanceById (_, id) {
    // return state.financeList.filter(item => item.id === id)
    return await services.attemptGetFinanceById(id)
  },

  async attemptSyncOrder (_, orderId) {
    return await services.attemptSyncOrder(orderId)
  },

  async attemptSyncAllOrders () {
    return await services.attemptSyncAllOrders()
  },

  async attemptGenerateReceipt (_) {
    return await services.attemptGenerateReceipt()
  }
}

export const FinanceModule = {
  module: {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }
}
