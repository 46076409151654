import * as TYPES from './mutations-types'
import { mixin } from '../../../mixins'

export default {
  [TYPES.SET_SYSTEM_EMAILS_LIST] (state, loadedList) {
    state.systemEmailsList = loadedList
  },
  [TYPES.SET_MANAGE_SYSTEM_EMAIL] (state, systemEmail) {
    state.manageSystemEmail = mixin.methods.deepClone(systemEmail)
    state.manageSystemEmail = mixin.methods.hydrateSystemEmail(state.manageSystemEmail, systemEmail)
  },
  [TYPES.UPDATE_ACTIVE_SYSTEM_EMAIL] (state, systemEmail) {
    state.manageSystemEmail = systemEmail
    state.manageSystemEmail = mixin.methods.hydrateSystemEmail(state.manageSystemEmail, systemEmail)
  }
}
