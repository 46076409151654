const GroupsList = () => import('./components/GroupsList')
const GroupsManage = () => import('./components/GroupsManage')
const GroupsConnections = () => import('./components/GroupsConnections')
const GroupsNewConnection = () => import('./components/GroupsNewConnection')

export default [
  {
    name: 'groups.index',
    path: '/groups',
    component: GroupsList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'groups',
        access: 'write'
      }
    },
    children: [
      {
        name: 'groups.connections',
        path: 'connections/:id/:type',
        component: GroupsConnections,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'groups',
            access: 'write'
          }
        }
      },
      {
        name: 'groups.connections.new',
        path: 'connections/:id/new?',
        component: GroupsNewConnection,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'groups',
            access: 'write'
          }
        }
      }
    ]
  },
  {
    name: 'groups.manage',
    path: '/groups/manage/:id?',
    component: GroupsManage,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'groups',
        access: 'write'
      }
    }
  }
]
