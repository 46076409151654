import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptGetTotalEnrollments = ({ commit }) => {
  return services.getTotalEnrollments()
    .then(({ data }) => {
      commit(TYPES.SET_DASHBOARD_TOTAL_ENROLLMENT_DATA, data)

      return data
    })
}

export const attemptGetTotalRegistersLastWeeks = ({ commit }) => {
  return services.getTotalRegistersLastWeeks()
    .then(({ data }) => {
      commit(TYPES.SET_DASHBOARD_TOTAL_USER_DATA, data)

      return data
    })
}

export const attemptGetTotalEnrollmentsBySolution = ({ commit }) => {
  return services.getTotalEnrollmentsBySolution()
    .then(({ data }) => {
      commit(TYPES.SET_DASHBOARD_TOTAL_ENROLLMENTS_SOLUTION_DATA, data)

      return data
    })
}

export const attemptGetTotalEnrollmentsByDay = ({ commit }) => {
  return services.getTotalEnrollmentsByDay()
    .then(({ data }) => {
      commit(TYPES.SET_DASHBOARD_TOTAL_ENROLLMENTS_SOLUTION_DAYS_DATA, data)

      return data
    })
}

export const attemptGetTotalEnrollmentsSolutionsByStatus = ({ commit }) => {
  return services.getTotalEnrollmentsSolutionsByStatus()
    .then(({ data }) => {
      commit(TYPES.SET_DASHBOARD_TOTAL_ENROLLMENTS_TRACKS_STATUS_DATA, data)

      return data
    })
}

export const attemptGetTotalEnrollmentsTracksByStatus = ({ commit }) => {
  return services.getTotalEnrollmentsTracksByStatus()
    .then(({ data }) => {
      commit(TYPES.SET_DASHBOARD_TOTAL_ENROLLMENTS_TRACKS_STATUS_DATA, data)

      return data
    })
}
