<script>
import { mapState } from 'vuex'

export default {
  name: 'NotFoundPage',
  components: {
    Action: () => import('@/components/general/Action'),
    Logo: () => import('@/components/general/Logo')
  },
  computed: {
    ...mapState(['Account'])
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'auth.signin' })
    }
  }
}
</script>

<template>
  <div class="not-found">
    <div class="not-found-content vertical-center">
      <logo
        v-if="$theme === 'default' && !Account.user"
        dark
      />
      <h1>4o4</h1>
      <p>{{ $t('404:message') }}</p>
      <action
        type="button"
        :text="$t('404:btn')"
        secondary
        large
        @click="goBack()"
      />
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/notfound.css"></style>
