import * as TYPES from './types'

/* eslint-disable no-param-reassign */
export default {
  [TYPES.MAIN_SET_FETCHING] (state, fetching) {
    state.fetching = fetching
  },
  [TYPES.MAIN_SET_FEEDBACK] (state, obj) {
    const newObj = Object.assign({
      title: null,
      message: null,
      textColor: null,
      backgroundColor: null
    }, obj)
    newObj.random = Math.random()
    state.feedback = newObj
  },
  [TYPES.MAIN_SET_ACCESSIBILITY] (state) {
    state.accessibility = !state.accessibility
  },
  [TYPES.MAIN_SET_LANGUAGE] (state, language) {
    state.language = language
  },
  [TYPES.MAIN_SET_FONTSIZE] (state, size) {
    state.accessibilityFontSize = size
  },
  [TYPES.MAIN_SET_FULLSCREEN] (state) {
    state.fullscreen = !state.fullscreen
  },
  resetState (state) {
    state.Auth = {}
    state.Account.user = {}
  }
}
