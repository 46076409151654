export const levelsList = getters => {
  return getters.levelsList
}

export const achievementsList = getters => {
  return getters.achievementsList
}

export const playersList = getters => {
  return getters.playersList
}

export const getPlayerProfile = getters => {
  return getters.playerProfile
}
