import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptGetThematicPagesList = ({ commit }, pagination) => {
  return services.getThematicPagesList(pagination)
    .then(thematicPagesList => {
      commit(TYPES.SET_THEMATICPAGES_LIST, thematicPagesList.data.data)

      return {
        data: thematicPagesList.data.data,
        firstPage: thematicPagesList.data.firstPage,
        lastPage: thematicPagesList.data.lastPage,
        nextPage: thematicPagesList.data.nextPage,
        previousPage: thematicPagesList.data.previousPage,
        actualPage: thematicPagesList.data.actualPage,
        total: thematicPagesList.data.total
      }
    })
}

export const attemptRemoveThematicPage = (_, data) => {
  return services.removeThematicPage(data)
    .then(response => {
      return response
    })
}

export const attemptToggleActiveThematicPage = (_, idThematicPage) => {
  return services.toggleActiveThematicPage(idThematicPage)
    .then(response => {
      return response
    })
}

export const attemptGetThematicPage = (_, thematicPageId) => {
  return services.getThematicPage(thematicPageId)
}

export const attemptCreateThematicPage = (_, payload) => {
  return services.createThematicPage(payload)
}

export const attemptUpdateThematicPage = (_, payload) => {
  return services.updateThematicPage(payload)
}

export const attemptGetThematicPageTypesList = (_, pagination) => {
  return services.getThematicPageTypesList(pagination)
    .then(thematicPageTypesList => {
      return {
        data: thematicPageTypesList.data.data,
        firstPage: thematicPageTypesList.data.firstPage,
        lastPage: thematicPageTypesList.data.lastPage,
        nextPage: thematicPageTypesList.data.nextPage,
        previousPage: thematicPageTypesList.data.previousPage,
        total: thematicPageTypesList.data.total
      }
    })
}
