<script>
export default {
  name: 'NotAllowed'
}
</script>

<template>
  <section class="not-allowed-page">
    <img src="/assets/images/themes/default/svg/underconstruction.svg">
    <h2>Você não tem permissão para acessar essa página</h2>
    <p>Por favor utilize a opções disponíveis no menu lateral</p>
  </section>
</template>

<style lang="scss" src="@/assets/styles/themes/default/notallowed.scss"></style>
