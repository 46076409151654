const state = {
  solutionsList: [],
  manageSolution: null,
  pagination: {
    query: {},
    filter: {},
    order: {
      active: 'desc',
      name: 'asc'
    },
    limit: 12,
    page: 1,
    lastPage: null
  }
}

export const pagination = state.pagination

export default state
