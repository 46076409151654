const Dashboard = () => import('./components/Dashboard')

export default [
  {
    name: 'dashboard.index',
    path: '/dashboard',
    component: Dashboard,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      module: 'dashboard'
    }
  }
]
