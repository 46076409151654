<script>

export default {
  name: 'ServiceUnavailablePage',
  components: {
  }
}
</script>

<template>
  <div class="service-unavailable">
    <div class="service-unavailable-content vertical-center">
      <Logo
        v-if="$theme === 'default'"
        dark
      />
      <h1>503</h1>
      <p>Serviço Indisponível</p>
      <p>
        Desculpe, o serviço do banco está indisponível<br>
        Sugerimos que você retorne à página inicial e tente novamente em alguns minutos.
      </p>
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/service-unavailable.css"></style>
