import { http } from '@/support/http'
import { createQuery } from '../../../support/utils/paginatorQueryBuilder'
import { parseToFormDataWithArray } from '@/support/payloadParser'

export const getEventsList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/events?${queryString}`)
}

export const getEventsSearch = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/events/search?${queryString}`)
}

export const getEvent = (idEvent) => {
  return http.get(`api/event/${idEvent}`)
}

export const getThemesList = (pagination) => {
  pagination.limit = 1000
  const queryString = createQuery(pagination)

  return http.get(`showcase/themes?${queryString}`)
}

export const getEntrepreneurProfilesList = (pagination) => {
  pagination.limit = 1000
  const queryString = createQuery(pagination)

  return http.get(`showcase/entrepreneur_profiles?${queryString}`)
}

export const getAgendasList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/event/list/schedules?${queryString}`)
}

export const getAgendasSearch = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/agendas/search?${queryString}`)
}

export const getAgenda = (idAgenda) => {
  return http.get(`api/event/schedule/${idAgenda}`)
}

export const saveEvent = ({ data }) => {
  const form = parseToFormDataWithArray(data)

  if (data.image && typeof data.image === 'object') {
    form.append('image', data.image, data.image.name)
  }

  if (data.card_image && typeof data.card_image === 'object') {
    form.append('card_image', data.card_image, data.card_image.name)
  }

  return http.post('api/event', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// export const updateEvent = ({ id, data }) => {
//   const form = parseToFormDataWithArray(data)

//   return http.post(`api/event/${id}`, form, {
//     headers: {
//       'Content-Type': 'multipart/form-data'
//     }
//   })
// }

export const updateEvent = ({ id, data }) => {
  const form = parseToFormDataWithArray(data)

  if (data.image && typeof data.image === 'object') {
    form.append('image', data.image, data.image.name)
  }

  if (data.card_image && typeof data.card_image === 'object') {
    form.append('card_image', data.card_image, data.card_image.name)
  }

  return http.post(`api/event/${id}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const removeEvent = (idEvent) => {
  return http.delete(`api/event/${idEvent}`)
}

export const toggleActiveEvent = (idEvent) => {
  return http.put(`api/event/${idEvent}/toggle`)
}

export const saveAgenda = ({ data }) => {
  const form = parseToFormDataWithArray(data)

  return http.post('api/event_schedule', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const updateAgenda = (payload) => {
  return http.put(`api/event/schedule/${payload.id}`, payload.data)
}

export const toggleActiveAgenda = (idAgenda) => {
  return http.put(`api/event/schedule/${idAgenda}/toggle`)
}

export const getCancellationList = () => {
  return http.get('api/cancellation_reason')
}
