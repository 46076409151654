import { http } from '@/support/http'

export const getLevelsList = () => {
  return http.get('api/gamification/levels')
}

export const getAchievementsList = () => {
  return http.get('api/gamification/achievements')
}

export const getRankingPlayersList = () => {
  return http.get('api/gamification/ranking/players')
}

export const getPlayerProfile = (username) => {
  return http.get(`api/gamification/profile/${username}`)
}
