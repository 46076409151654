import { http } from '@/support/http'
import { createQuery } from '@/support/utils/paginatorQueryBuilder'
import { parseToFormDataWithArray } from '@/support/payloadParser'

export const getThematicPagesList = (pagination) => {
  const queryString = createQuery(pagination)
  return http.get(`api/thematic_pages?${queryString}`)
}

export const removeThematicPage = (idThematicPage) => {
  return http.delete(`api/thematic_page/${idThematicPage}`)
}

export const toggleActiveThematicPage = (idThematicPage) => {
  return http.post(`api/thematic_page/${idThematicPage}/toggle`)
}

export const getThematicPage = (idThematicPage) => {
  return http.get(`api/thematic_page/${idThematicPage}`)
}

export const createThematicPage = (payload) => {
  const form = parseToFormDataWithArray(payload)
  if (payload.image_logo && typeof payload.image_logo === 'object') {
    form.append('image_logo', payload.image_logo, payload.image_logo.name)
  }
  if (payload.image_banner && typeof payload.image_banner === 'object') {
    form.append('image_banner', payload.image_banner, payload.image_banner.name)
  }
  return http.post('api/thematic_page', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const updateThematicPage = (payload) => {
  const form = parseToFormDataWithArray(payload)
  if (payload.image_logo && typeof payload.image_logo === 'object') {
    form.append('image_logo', payload.image_logo, payload.image_logo.name)
  }
  if (payload.image_banner && typeof payload.image_banner === 'object') {
    form.append('image_banner', payload.image_banner, payload.image_banner.name)
  }
  return http.post(`api/thematic_page/${payload.id_thematic_page}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const getThematicPageTypesList = (pagination) => {
  const queryString = createQuery(pagination)
  return http.get(`showcase/thematic_pages/types?${queryString}`)
}
