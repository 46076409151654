import * as TYPES from './mutations-types'
import { mixin } from '../../../mixins'

export default {
  [TYPES.SET_TRACKS_LIST] (state, loadedList) {
    state.tracksList = loadedList
  },
  [TYPES.SET_MANAGE_TRACK] (state, track) {
    state.manageTrack = mixin.methods.deepClone(track)
  },
  [TYPES.UPDATE_ACTIVE_TRACK] (state, track) {
    state.manageTrack = track
  }
}
