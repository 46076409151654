export const solutionsList = getters => {
  return getters.solutionsList
}

export const getManagingSolution = getters => {
  return getters.manageSolution
}

export const getPagination = getters => {
  return getters.pagination
}
