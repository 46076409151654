import { pagination as initialPagination } from './state'
import * as TYPES from './mutations-types'
import { mixin } from '../../../mixins'

export default {
  [TYPES.SET_SOLUTIONS_LIST] (state, loadedList) {
    state.solutionsList = loadedList
  },
  [TYPES.SET_MANAGE_SOLUTION] (state, solution) {
    state.manageSolution = mixin.methods.deepClone(solution)
    state.manageSolution = mixin.methods.hydrateSolution(state.manageSolution, solution)
  },
  [TYPES.UPDATE_ACTIVE_SOLUTION] (state, solution) {
    state.manageSolution = solution
    state.manageSolution = mixin.methods.hydrateSolution(state.manageSolution, solution)
  },
  [TYPES.SET_PAGINATION] (state, pagination) {
    state.pagination = pagination
  },
  [TYPES.RESET_PAGINATION] (state) {
    state.pagination = initialPagination
  }
}
