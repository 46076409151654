const TracksList = () => import('./components/TracksList')
const TracksManage = () => import('./components/TracksManage')
const TracksManageQualify = () => import('./components/TracksManageQualify')
const TracksManageSEO = () => import('./components/TracksManageSEO')
const TracksManageLink = () => import('./components/TracksManageLink')

export default [
  {
    name: 'tracks.index',
    path: '/tracks',
    component: TracksList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'tracks',
        access: 'write'
      }
    }
  },
  {
    name: 'tracks.manage',
    path: '/tracks/manage/:id?',
    component: TracksManage,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'tracks',
        access: 'write'
      }
    }
  },
  {
    name: 'tracks.manage.qualify',
    path: '/tracks/manage/:id/qualify/:isNew',
    component: TracksManageQualify,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'tracks',
        access: 'write'
      }
    }
  },
  {
    name: 'tracks.manage.seo',
    path: '/tracks/manage/:id/seo/:isNew',
    component: TracksManageSEO,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'tracks',
        access: 'write'
      }
    }
  },
  {
    name: 'tracks.manage.link',
    path: '/tracks/manage/:id/link/:isNew',
    component: TracksManageLink,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'tracks',
        access: 'write'
      }
    }
  }
]
