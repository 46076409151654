const ThematicPagesList = () => import('./components/ThematicPagesList')
const ThematicPagesManage = () => import('./components/ThematicPagesManage')

export default [
  {
    name: 'thematicPages.index',
    path: '/thematic_pages',
    component: ThematicPagesList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'thematic_pages',
        access: 'write'
      }
    }
  },
  {
    name: 'thematicPages.manage',
    path: '/thematic_pages/manage/:id?',
    component: ThematicPagesManage,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'thematic_pages',
        access: 'write'
      }
    }
  }
]
