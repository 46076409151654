import { http } from '@/support/http'
import { createQuery } from '../../../support/utils/paginatorQueryBuilder'

export const getCommentsList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/assessment?${queryString}`)
}

export const getCommentsSearch = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/assessment/search?${queryString}`)
}

export const disapprovedComment = (idComment) => {
  return http.post(`api/assessment/${idComment}/disapproved`)
}

export const approvedComment = (idComment) => {
  return http.post(`api/assessment/${idComment}/approved`)
}
