import { http } from '@/support/http'
import { createQuery } from '../../../support/utils/paginatorQueryBuilder'
import { parseToFormDataWithArray } from '@/support/payloadParser'
import qs from 'qs'

export const getSystemEmailsList = (pagination) => {
  const queryString = createQuery(pagination)
  return http.get(`api/notification/system_emails?${queryString}`)
}

export const getSystemEmail = (data) => {
  let url = `api/notification/system_email/${data.idSystemEmail}`
  if (data.replaceTitle) {
    url = url + '?replaceTitle=true'
  }
  return http.get(url)
}

export const viewSystemEmail = (idSystemEmail) => {
  return http.get(`showcase/notification/system_email/${idSystemEmail}/preview`)
}

export const updateSystemEmail = ({ id, data }) => {
  const form = parseToFormDataWithArray(data)
  return http.post(`api/notification/system_email/${id}`, form, {})
}

export const toggleSystemEmail = (idSystemEmail) => {
  return http.post(`api/notification/system_email/${idSystemEmail}/toggle`)
}

export const getFiltersList = (pagination) => {
  const queryString = createQuery(pagination)
  return http.get(`api/filters?${queryString}`)
}

export const getFilter = (id) => {
  return http.get(`api/filters/${id}`)
}

export const saveFilter = (form) => {
  return http.post('api/filters', form)
}

export const updateFilter = ({ id, data }) => {
  return http.put(`api/filters/${id}`, data)
}

export const runFilter = (data) => {
  const queryString = qs.stringify({ entity_ids: data.entityIds || [] })
  return http.get(`api/filters/${data.id}/run?${queryString}`)
}

export const toggleActiveFilter = (id) => {
  return http.patch(`api/filters/${id}/toggle`)
}

export const getMediationPlanList = (query) => {
  const queryString = createQuery(query)
  return http.get(`api/mediation-plans?${queryString}`)
}

export const getMediationPlan = (id) => {
  return http.get(`api/mediation-plans/${id}`)
}

export const createMediationPlan = (form) => {
  return http.post('api/mediation-plans', form)
}

export const updateMediationPlan = ({ id, form }) => {
  return http.put(`api/mediation-plans/${id}`, form)
}

export const toggleActiveMediationPlan = (id) => {
  return http.patch(`api/mediation-plans/${id}/toggle`)
}

export const removeMediationPlan = (id) => {
  return http.delete(`api/mediation-plans/${id}`)
}

export const getLogList = (payload) => {
  const queryString = createQuery(payload.query)
  return http.get(`api/mediation-plans/${payload.id}/logs?${queryString}`)
}
