export const usersList = getters => {
  return getters.usersList
}

export const getManagingUser = getters => {
  return getters.manageUser
}

export const userSessionsList = getters => {
  return getters.userSessionsList
}

export const userLoginsList = getters => {
  return getters.userLoginsList
}

export const userEmailLogsList = getters => {
  return getters.userEmailLogsList
}

export const userLearningPathList = getters => {
  return getters.userLearningPathList
}

export const userCanceledList = getters => {
  return getters.userCanceledList
}
