const Comments = () => import('./components/Comments')

export default [
  {
    name: 'comments.index',
    path: '/comments',
    component: Comments,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      module: 'assessment',
      permission: {
        context: 'comments',
        access: 'write'
      }
    }
  }
]
