import store from '@/store'

const needAuth = auth => auth === true
const isLocked = locked => locked === true

const beforeEach = (to, from, next) => {
  const auth = to.meta.requiresAuth
  const softLock = to.meta.softLock

  store.dispatch('setFetching', false)

  if (isLocked(softLock)) {
    next({ name: 'dashboard.profile.pending' })

    return
  }

  if (!needAuth(auth)) {
    next()

    return
  }

  if (to.name === from.name) {
    next()

    return
  }

  store.dispatch('attemptCheckUserToken')
    .then(() => {
      next()
    })
    .catch(() => {
      next({ name: 'signin' })
    })
}

export default beforeEach
